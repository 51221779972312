import { render, staticRenderFns } from "./FriendItem.vue?vue&type=template&id=ba8cbc92&scoped=true&"
import script from "./FriendItem.vue?vue&type=script&lang=js&"
export * from "./FriendItem.vue?vue&type=script&lang=js&"
import style0 from "./FriendItem.vue?vue&type=style&index=0&id=ba8cbc92&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba8cbc92",
  null
  
)

export default component.exports