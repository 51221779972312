import { render, staticRenderFns } from "./ProfileCollection.answers.vue?vue&type=template&id=017ea490&scoped=true&"
import script from "./ProfileCollection.answers.vue?vue&type=script&lang=js&"
export * from "./ProfileCollection.answers.vue?vue&type=script&lang=js&"
import style0 from "./ProfileCollection.answers.vue?vue&type=style&index=0&id=017ea490&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "017ea490",
  null
  
)

export default component.exports